@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .width-layout {
    @apply w-11/12 mx-auto md:w-9/12 xl:w-8/12;
  }
  .width-layout-xl {
    @apply w-11/12 mx-auto md:w-9/12 xl:w-5/6;
  }

  /* This ensures proper ring style when focusing on an element */
  .ring-light {
    @apply ring-offset-black ring-blue-300 ring-2 ring-offset-1 ring-opacity-75;
  }
  .ring-dark {
    @apply ring-offset-white ring-blue-300 ring-2 ring-offset-1 ring-opacity-75;
  }

  /* This ensures proper styling of tables when we render HTML */
  .html-string-container :is(table, th, td) {
    @apply border border-black;
  }

  /* For all instances of "ul" where we define html-string-container, this ensures we can see the bullet points and that they are styled with "•" */
  .html-string-container :is(ul) {
    @apply list-disc m-[revert] p-[revert];
  }
  /* For all instances of nested "ul" where we define html-string-container, this ensures they are styled with "○" */
  .html-string-container :is(ul ul) {
    @apply list-[circle];
  }
  /* For all instances of double-nested "ul" where we define html-string-container, this ensures they are styled with "■" */
  .html-string-container :is(ul ul ul) {
    @apply list-[square];
  }

  /* For all instances of "ol" where we define html-string-container, this ensures we can see the bullet points and that they are styled with "1.", "2.", "3.", etc. */
  .html-string-container :is(ol) {
    @apply list-decimal m-[revert] p-[revert];
  }
  /* For all instances of nested "ol" where we define html-string-container, this ensures they are styled with "a.", "b.", "c.", etc. */
  .html-string-container :is(ol ol) {
    @apply list-[lower-alpha];
  }
  /* For all instances of double-nested "ol" where we define html-string-container, this ensures they are styled with "i.", "ii.", "iii.", etc. */
  .html-string-container :is(ol ol ol) {
    @apply list-[lower-roman];
  }

  /* Due to markdown rendering in the chat/chat summary, for all instances of "ul" or "ol", this ensures we can see the bullet points and that they are styled appropriately */
  .summary-list-spacing :is(ul, ol) {
    @apply pl-3 whitespace-normal child:mb-1;
  }
  .content-list-spacing :is(ul, ol) {
    @apply pl-5 whitespace-normal child:mb-1;
  }
  .markdown-list-renderer :is(ul) {
    @apply list-disc;
  }
  .markdown-list-renderer :is(ul ul) {
    @apply list-[circle];
  }
  .markdown-list-renderer :is(ul ul ul) {
    @apply list-[square];
  }
  .markdown-list-renderer :is(ol) {
    @apply list-decimal;
  }
  .markdown-list-renderer :is(ol ol) {
    @apply list-[lower-alpha];
  }
  .markdown-list-renderer :is(ol ol ol) {
    @apply list-[lower-roman];
  }
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/opensans-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansCondensed';
  src: url('./fonts/opensans-condensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
  .border-gradient-purple {
    border-image: linear-gradient(#b497ff, #7213ea) 30;
    border-style: solid;
  }
  .border-gradient-cobalt-blue {
    border-image: linear-gradient(#00b8f5, #1e4ae2) 30;
    border-style: solid;
  }
}

@keyframes gradient-move {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

/* Customize the global "highlight" background color when selecting text */
*::selection {
  background-color: rgb(152, 185, 226, 0.7);
}
