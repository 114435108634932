@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('../fonts/opensans-condensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
